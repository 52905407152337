import { labCommandCallback } from "@/lib/polkadotProvider/command/labs";
export async function createCertification(api, pair, data) {
  let callback = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : () => {};
  const unsub = await api.tx.certifications.createCertification(data).signAndSend(pair, {
    nonce: -1
  }, async _ref => {
    let {
      events,
      status
    } = _ref;
    await labCommandCallback(api, pair, {
      events,
      status,
      callback,
      unsub
    });
  });
}
export async function createCertificationFee(api, pair, data) {
  const result = await api.tx.certifications.createCertification(data).paymentInfo(pair);
  return result;
}
export async function updateCertification(api, pair, certificationId, data) {
  let callback = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : () => {};
  const unsub = await api.tx.certifications.updateCertification(certificationId, data).signAndSend(pair, {
    nonce: -1
  }, async _ref2 => {
    let {
      events,
      status
    } = _ref2;
    await labCommandCallback(api, pair, {
      events,
      status,
      callback,
      unsub
    });
  });
}
export async function updateCertificationFee(api, pair, certificationId, data) {
  const result = await api.tx.certifications.updateCertification(certificationId, data).paymentInfo(pair);
  return result;
}
export async function deleteCertification(api, pair, certificationId) {
  let callback = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : () => {};
  const unsub = await api.tx.certifications.deleteCertification(certificationId).signAndSend(pair, {
    nonce: -1
  }, async _ref3 => {
    let {
      events,
      status
    } = _ref3;
    await labCommandCallback(api, pair, {
      events,
      status,
      callback,
      unsub
    });
  });
}
export async function deleteCertificationFee(api, pair, certificationId) {
  const result = await api.tx.certifications.deleteCertification(certificationId).paymentInfo(pair);
  return result;
}