import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.replace-all.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from "vuex";
import { updateLab, updateLabFee, unstakeLab, unstakeLabFee } from "@/lib/polkadotProvider/command/labs";
import { getLocations, getStates, getCities, getOrdersData } from "@/lib/api";
import Kilt from "@kiltprotocol/sdk-js";
import CryptoJS from "crypto-js";
import { u8aToHex } from "@polkadot/util";
import Certification from "./Certification";
import { uploadFile, getFileUrl } from "@/lib/pinata-proxy";
import serviceHandler from "@/lib/serviceHandler";
import DialogUnstake from "@/components/Dialog/DialogUnstake";
import { generalDebounce } from "@/utils";
import Dialog from "@/components/Dialog";
import Button from "@/components/Button";
import DialogErrorBalance from "@/components/Dialog/DialogErrorBalance";
const englishAlphabet = val => val && /^[A-Za-z0-9!@#$%^&*\\(\\)\-_=+:;"',.\\/? ]+$/.test(val) || "This field can only contain English alphabet";
export default {
  name: "LabAccount",
  mixins: [serviceHandler],
  components: {
    Certification,
    DialogUnstake,
    Dialog,
    Button,
    DialogErrorBalance
  },
  data: () => ({
    document: {
      name: "",
      email: "",
      address: "",
      phoneNumber: "",
      website: "",
      country: "",
      region: "",
      city: ""
    },
    phoneCode: null,
    imageUrl: "",
    countries: [],
    states: [],
    cities: [],
    files: [],
    isEditable: false,
    isUploading: false,
    showActiveOrder: false,
    stakeStatus: "",
    unstakeAt: "",
    stakingAmount: "",
    unstakeDialog: false,
    unstakeLoading: false,
    unstakeFee: 0,
    verificationStatus: "",
    isVerify: false,
    isShowError: false,
    fee: 0
  }),
  computed: {
    ...mapGetters({
      api: "substrate/getAPI",
      pair: "substrate/wallet",
      labAccount: "substrate/labAccount"
    }),
    ...mapState({
      mnemonic: state => state.substrate.mnemonicData,
      web3: state => state.metamask.web3
    }),
    citiesSelection() {
      return this.cities.filter(c => c.country == this.country).map(c => ({
        value: c.city,
        text: c.city,
        country: c.country
      }));
    },
    rules() {
      return [file => !file || file.size <= 3097152 || "The total file size uploaded exceeds the maximum file size allowed (3MB)", file => !file || file.type == "image/jpg" || file.type == "image/jpeg" || "Document type should be image/jpg/jpeg"];
    },
    isRequired() {
      return [val => !!val || "This field is required"];
    },
    emailRules() {
      return [val => !!val || "This field is required", val => /^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/.test(val) || "Email is invalid. It should contain @ followed by a domain", val => val && val.length <= 255 || "This field only allows 255 characters", val => val && /^[A-Za-z0-9 ]?[A-Za-z0-9@. ]+$/.test(val) || "This field only allows Alphabetic characters."];
    },
    nameRules() {
      return [val => !!val || "This field is required", val => val && val.length <= 100 || "This field only allows 100 characters", englishAlphabet];
    },
    addressRules() {
      return [val => !!val || "This field is required", val => val && val.length <= 255 || "This field only allows 255 characters", englishAlphabet];
    },
    phoneNumberRules() {
      return [val => !!val || "This field is required", val => /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/.test(val) || "This field can only contain number"];
    },
    websiteRules() {
      return [val => !!val || "This field is required", val => /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&/=]*)/.test(val) || "Website is invalid. It should contain protocol (https://) followed by a domain", val => val && val.length <= 255 || "This field only allows 255 characters"];
    },
    computeUnstakeDate() {
      let date = new Date(this.unstakeAt);
      date.setDate(date.getDate() + 6);
      return date.toLocaleDateString("en-US");
    }
  },
  watch: {
    mnemonic(val) {
      if (val) this.getKiltBoxPublicKey();
    },
    document: {
      deep: true,
      immediate: true,
      handler: generalDebounce(async function () {
        if (this.mnemonic) await this.getUpdateLabFee();
      }, 500)
    }
  },
  async created() {
    await this.getLabInfo();
    await this.getUnstakeFee();
  },
  methods: {
    async getLabInfo() {
      var _this$imageUrl$split$, _this$imageUrl;
      const {
        address,
        city,
        country,
        email,
        name,
        phoneNumber,
        profileImage,
        region,
        website
      } = this.labAccount.info;
      this.document = {
        name,
        email,
        address,
        phoneNumber,
        website
      };
      this.verificationStatus = this.labAccount.verificationStatus;
      await this.getCountries();
      await this.onCountryChange(country);
      await this.onStateChange(region);
      await this.onCityChange({
        name: city
      });
      this.imageUrl = profileImage;
      const res = await fetch(this.imageUrl);
      const blob = await res.blob(); // Gets the response and returns it as a blob
      const file = new File([blob], (_this$imageUrl$split$ = (_this$imageUrl = this.imageUrl) === null || _this$imageUrl === void 0 ? void 0 : _this$imageUrl.split("/").pop()) !== null && _this$imageUrl$split$ !== void 0 ? _this$imageUrl$split$ : "Profile Image File", {
        type: "image/jpeg"
      });
      this.files = file;
      const stakeAmount = Number(this.labAccount.stakeAmount.replaceAll(",", ""));
      this.stakingAmount = "".concat(new Intl.NumberFormat("en-US").format(stakeAmount / 10 ** 18), " DBIO");
      this.stakeStatus = this.labAccount.stakeStatus;
      if (this.labAccount.unstakeAt !== 0) {
        const dateOfBirth = this.labAccount.unstakeAt.replaceAll(",", "");
        const _dateOfBirth = new Date(+dateOfBirth).toLocaleDateString();
        this.unstakeAt = _dateOfBirth;
      }
    },
    async getCountries() {
      const {
        data: {
          data
        }
      } = await this.dispatch(getLocations);
      this.countries = data;
    },
    async onCountryChange(selectedCountry) {
      var _selected$iso, _selected$iso2, _selected$phone_code;
      const selected = typeof selectedCountry !== "string" ? selectedCountry : this.countries.find(country => country.iso2 === selectedCountry);
      this.document.region = "";
      this.document.city = "";
      const {
        data: {
          data
        }
      } = await this.dispatch(getStates, (_selected$iso = selected === null || selected === void 0 ? void 0 : selected.iso2) !== null && _selected$iso !== void 0 ? _selected$iso : selectedCountry);
      this.states = data;
      this.document.country = (_selected$iso2 = selected === null || selected === void 0 ? void 0 : selected.iso2) !== null && _selected$iso2 !== void 0 ? _selected$iso2 : selectedCountry;
      this.phoneCode = (_selected$phone_code = selected === null || selected === void 0 ? void 0 : selected.phone_code) !== null && _selected$phone_code !== void 0 ? _selected$phone_code : null;
    },
    async onStateChange(selectedState) {
      this.document.city = "";
      const {
        data: {
          data
        }
      } = await this.dispatch(getCities, this.document.country, selectedState);
      this.document.region = selectedState;
      this.cities = data;
    },
    onCityChange(_ref) {
      let {
        name
      } = _ref;
      this.document.city = name;
    },
    getKiltBoxPublicKey() {
      const cred = Kilt.Identity.buildFromMnemonic(this.mnemonic.toString(CryptoJS.enc.Utf8));
      return u8aToHex(cred.boxKeyPair.publicKey);
    },
    async getUpdateLabFee() {
      const boxPublicKey = this.getKiltBoxPublicKey();
      const {
        name,
        email,
        address,
        phoneNumber,
        website,
        country,
        city,
        region
      } = this.document;
      const fee = await updateLabFee(this.api, this.pair, {
        boxPublicKey,
        name,
        email,
        address,
        phoneNumber,
        website,
        country,
        city,
        region,
        profileImage: this.imageUrl
      });
      this.fee = this.web3.utils.fromWei(String(fee.partialFee), "ether");
    },
    async getUnstakeFee() {
      const fee = await unstakeLabFee(this.api, this.pair);
      this.unstakeFee = Number(this.web3.utils.fromWei(String(fee.partialFee), "ether"));
    },
    async handleShowUnstake() {
      const {
        data
      } = await getOrdersData(this.pair.address, null, null, null);
      const hasActiveOrder = data === null || data === void 0 ? void 0 : data.some(order => order._source.status === "Paid");
      if (hasActiveOrder) this.showActiveOrder = true;else this.unstakeDialog = true;
    },
    async updateLab() {
      if (!this.$refs.form.validate()) {
        return;
      }
      try {
        this.isLoading = true;
        const boxPublicKey = this.getKiltBoxPublicKey();
        const {
          name,
          email,
          address,
          phoneNumber,
          website,
          country,
          city,
          region
        } = this.document;
        await updateLab(this.api, this.pair, {
          boxPublicKey,
          name,
          email,
          address,
          phoneNumber,
          country,
          website,
          region,
          city,
          profileImage: this.imageUrl
        }, () => {
          this.isLoading = false;
          this.isEditable = false;
        });
      } catch (err) {
        if (err.message === "1010: Invalid Transaction: Inability to pay some fees , e.g. account balance too low") {
          this.isShowError = true;
        }
        console.error(err);
      }
    },
    async fileUploadEventListener(file) {
      this.imageUrl = "";
      if (!this.$refs.form.validate()) {
        return;
      }
      if (file && file.name) {
        if (file.name.lastIndexOf(".") <= 0) {
          return;
        }
        this.isUploading = true;
        this.isLoading = true;
        const dataFile = await this.setupFileReader(file);
        const result = await uploadFile({
          title: dataFile.name,
          type: dataFile.type,
          size: dataFile.size,
          file: dataFile
        });
        const link = getFileUrl(result.IpfsHash);
        this.imageUrl = link;
        this.isUploading = false;
        this.isLoading = false;
      } else {
        this.files = [];
        this.imageUrl = "";
      }
    },
    setupFileReader(value) {
      return new Promise((resolve, reject) => {
        const file = value;
        const fr = new FileReader();
        fr.onload = async function () {
          resolve(value);
        };
        fr.onerror = reject;
        fr.readAsArrayBuffer(file);
      });
    },
    async handleUnstakeLab() {
      this.unstakeLoading = true;
      try {
        await unstakeLab(this.api, this.pair);
      } catch (err) {
        if (err.message === "1010: Invalid Transaction: Inability to pay some fees , e.g. account balance too low") {
          this.isShowError = true;
        }
        console.error(err);
      }
      this.unstakeLoading = false;
    },
    closeDialog() {
      this.isShowError = false;
      this.isUploading = false;
    }
  }
};