import { queryEntireLabDataById } from "@/lib/polkadotProvider/query/labs";
import store from "@/store/index";
export async function registerLab(api, pair, data) {
  let callback = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : () => {};
  const unsub = await api.tx.labs.registerLab(data).signAndSend(pair, {
    nonce: -1
  }, async _ref => {
    let {
      events = [],
      status
    } = _ref;
    await labCommandCallback(api, pair, {
      events,
      status,
      callback,
      unsub
    });
  });
}
export async function registerLabFee(api, pair, data) {
  const result = await api.tx.labs.registerLab(data).paymentInfo(pair);
  return result;
}
export async function updateLab(api, pair, data) {
  let callback = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : () => {};
  const unsub = await api.tx.labs.updateLab(data).signAndSend(pair, {
    nonce: -1
  }, async _ref2 => {
    let {
      events = [],
      status
    } = _ref2;
    await labCommandCallback(api, pair, {
      events,
      status,
      callback,
      unsub
    });
  });
}
export async function updateLabFee(api, pair, data) {
  const result = await api.tx.labs.updateLab(data).paymentInfo(pair);
  return result;
}
export async function deregisterLab(api, pair) {
  const result = await api.tx.labs.deregisterLab().signAndSend(pair, {
    nonce: -1
  });
  return result.toHuman();
}
export async function deregisterLabFee(api, pair, id) {
  const result = await api.tx.labs.deregisterLab(id).paymentInfo(pair);
  return result;
}
export async function labCommandCallback(api, pair, _ref3) {
  let {
    events,
    status,
    callback,
    unsub
  } = _ref3;
  if (status.isFinalized) {
    // find/filter for success events
    const eventList = events.filter(_ref4 => {
      let {
        event
      } = _ref4;
      return api.events.system.ExtrinsicSuccess.is(event);
    });
    if (eventList.length > 0) {
      store.state.substrate.labAccount = await queryEntireLabDataById(api, pair.address);
      callback();
      unsub();
    }
  }
}
export async function unstakeLab(api, pair) {
  const result = await api.tx.labs.unstakeLab().signAndSend(pair, {
    nonce: -1
  });
  return result.toHuman();
}
export async function unstakeLabFee(api, pair, id) {
  const result = await api.tx.labs.updateLab(id).paymentInfo(pair);
  return result;
}
export async function stakeLab(api, pair) {
  const result = await api.tx.labs.stakeLab().signAndSend(pair, {
    nonce: -1
  });
  return result.toHuman();
}
export async function stakeLabFee(api, pair) {
  const result = await api.tx.labs.stakeLab().paymentInfo(pair);
  return result;
}