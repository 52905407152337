export default {
  data: () => ({
    isLoading: false,
    isErrors: null
  }),
  computed: {
    loadingPlaceholder() {
      return this.isLoading ? "Loading Data..." : "";
    }
  },
  methods: {
    /**
     * Adds a new callback function
     * @param args
     * @returns {Array}
     */
    addCallback(args) {
      // Add callback if doesn't exist
      if (!(args[args.length - 1] instanceof Function)) {
        args.push(() => {
          this.isLoading = false;
        });
        return args;
      }
      const f = args[args.length - 1];
      // Add new actions on callback
      args[args.length - 1] = () => {
        f();
        this.isLoading = false;
      };
      return args;
    },
    /**
     * Dispatch wrapper that marks the action as loading & parses errors
     * @param action
     * @param args
     * @returns {Promise<*>}
     */
    async dispatch(action) {
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }
      this.isLoading = true;
      args = this.addCallback(args);
      try {
        const response = await action.apply(this, args);
        return Promise.resolve(response);
      } catch (error) {
        var _error$response;
        this.isErrors = error;
        console.error("[".concat(error === null || error === void 0 ? void 0 : (_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.status, "] Something went wrong, Please try again later!"));
        return Promise.reject(error);
      }
    }
  }
};