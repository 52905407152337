//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Button",
  props: {
    outlined: Boolean,
    color: {
      type: String,
      default: "primary"
    },
    to: {
      type: [String, Object],
      default: null
    },
    elevation: String,
    dark: {
      type: Boolean,
      default: true
    },
    loading: Boolean
  },
  methods: {
    onClick() {
      this.$emit("click");
    }
  }
};