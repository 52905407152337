import "core-js/modules/web.dom-collections.iterator.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from "vuex";
import { createCertification, createCertificationFee, updateCertification, updateCertificationFee, deleteCertification, deleteCertificationFee } from "@/lib/polkadotProvider/command/labs/certifications";
import serviceHandler from "@/mixins/serviceHandler";
import Dialog from "@/components/Dialog";
import Button from "@/components/Button";
import { uploadFile, getFileUrl, getIpfsMetaData } from "@/lib/pinata-proxy";
import { generalDebounce } from "@/utils";
import DialogErrorBalance from "@/components/Dialog/DialogErrorBalance";
const englishAlphabet = val => val && /^[A-Za-z0-9!@#$%^&*\\(\\)\-_=+:;"',.\\/? ]+$/.test(val) || "This field can only contain English alphabet";
export default {
  name: "LabCertification",
  components: {
    Dialog,
    Button,
    DialogErrorBalance
  },
  mixins: [serviceHandler],
  data: () => ({
    certId: "",
    // for update certification
    certificationInfo: {
      title: "",
      issuer: "",
      month: "",
      year: "",
      description: "",
      supportingDocument: ""
    },
    certSupportingDocumentsUrl: "",
    selectMonths: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    certificationDialog: false,
    isUploading: false,
    currentLoading: null,
    showDeletePrompt: false,
    isEditCertificationDialog: false,
    files: [],
    fee: 0,
    isShowError: false
  }),
  props: {
    loading: Boolean
  },
  computed: {
    ...mapGetters({
      api: "substrate/getAPI",
      pair: "substrate/wallet",
      labAccount: "substrate/labAccount"
    }),
    ...mapState({
      web3: state => state.metamask.web3
    }),
    selectYears() {
      const years = [];
      const thisYear = new Date().getFullYear();
      for (let i = thisYear; i >= 2000; i--) {
        years.push(String(i));
      }
      return years;
    },
    supportingDocumentsRules() {
      return [file => !file || file.type == "application/pdf" || "Document type should be application/pdf", file => !file || file.size <= 2000000 || "Document size should be less than 2 MB!"];
    },
    titleRules() {
      return [val => !!val || "Title is required", val => val && val.length <= 50 || "This field only allows 50 characters", englishAlphabet];
    },
    issuerRules() {
      return [val => !!val || "Issuer is required", val => val && val.length <= 100 || "This field only allows 100 characters", englishAlphabet];
    },
    monthRules() {
      return [val => !!val || "This field is required"];
    },
    yearRules() {
      return [val => !!val || "This field is required"];
    },
    descriptionRules() {
      return [val => !!val || "This field is required", val => val && val.length <= 255 || "This field only allows 255 characters", englishAlphabet];
    }
  },
  async created() {
    this.getDetailDocument();
  },
  watch: {
    certificationInfo: {
      deep: true,
      immediate: true,
      handler: generalDebounce(async function () {
        if (this.isEditCertificationDialog) {
          await this.getUpdateCertificationFee();
          return;
        }
        await this.getCreateCertificationFee();
      }, 500)
    }
  },
  methods: {
    async getDetailDocument() {
      let certifications = [];
      for (const cert of this.labAccount.certifications) {
        var _cert$info$supporting, _rows$0$metadata$name;
        const {
          rows
        } = await getIpfsMetaData((_cert$info$supporting = cert.info.supportingDocument) === null || _cert$info$supporting === void 0 ? void 0 : _cert$info$supporting.split("/").pop());
        cert.info.documentName = (_rows$0$metadata$name = rows[0].metadata.name) !== null && _rows$0$metadata$name !== void 0 ? _rows$0$metadata$name : "Supporting Document File";
        cert.info.documentType = rows[0].metadata.keyvalues.type;
        certifications.push(cert);
      }
      this.labAccount.certifications = certifications;
    },
    async getCreateCertificationFee() {
      const fee = await createCertificationFee(this.api, this.pair, this.certificationInfo);
      this.fee = this.web3.utils.fromWei(String(fee.partialFee), "ether");
    },
    async getUpdateCertificationFee() {
      const fee = await updateCertificationFee(this.api, this.pair, this.certId, this.certificationInfo);
      this.fee = this.web3.utils.fromWei(String(fee.partialFee), "ether");
    },
    async getDeleteCertificationFee() {
      const fee = await deleteCertificationFee(this.api, this.pair, this.certId);
      this.fee = this.web3.utils.fromWei(String(fee.partialFee), "ether");
    },
    openCertificationDialog() {
      this.certificationDialog = true;
    },
    closeCertificationDialog() {
      this.certId = "";
      this.certificationDialog = false;
      this.isEditCertificationDialog = false;
      this.$refs.certificationForm.reset();
    },
    async submitCertification() {
      if (!this.certId) {
        await this.addCertification();
        return;
      }
      await this.updateCertificationDocument();
    },
    async addCertification() {
      if (!this.$refs.certificationForm.validate()) {
        return;
      }
      try {
        this.certificationInfo.supportingDocument = this.certSupportingDocumentsUrl;
        await this.dispatch(createCertification, this.api, this.pair, this.certificationInfo, () => {
          this.closeCertificationDialog();
          this.getDetailDocument();
        });
      } catch (error) {
        this.isLoading = false;
        if (error.message === "1010: Invalid Transaction: Inability to pay some fees , e.g. account balance too low") {
          this.isShowError = true;
        }
      }
    },
    async editCertification(cert) {
      try {
        this.currentLoading = null;
        this.currentLoading = cert.id;
        const {
          title,
          issuer,
          month,
          year,
          description,
          supportingDocument,
          documentName,
          documentType: type
        } = cert.info;
        this.certId = cert.id;
        this.certificationInfo = {
          title,
          issuer,
          month,
          year,
          description,
          supportingDocument
        };
        const res = await fetch(supportingDocument);
        const blob = await res.blob(); // Gets the response and returns it as a blob
        const file = new File([blob], documentName, {
          type
        });
        this.files = file;
        this.isEditCertificationDialog = true;
        this.certificationDialog = true;
        this.currentLoading = null;
      } catch (error) {
        console.error(error);
        this.currentLoading = null;
      }
    },
    async updateCertificationDocument() {
      if (!this.$refs.certificationForm.validate()) {
        return;
      }
      if (this.certSupportingDocumentsUrl) {
        this.certificationInfo.supportingDocument = this.certSupportingDocumentsUrl;
      }
      await this.dispatch(updateCertification, this.api, this.pair, this.certId, this.certificationInfo, () => {
        this.closeCertificationDialog();
        this.getDetailDocument();
      });
    },
    async showDelete(cert) {
      if (this.labAccount.certifications.length === 1 && this.labAccount.verificationStatus === "Verified") return;
      this.certId = cert.id;
      this.getDeleteCertificationFee();
      this.showDeletePrompt = true;
    },
    async deleteCertification() {
      await this.dispatch(deleteCertification, this.api, this.pair, this.certId, () => {
        this.getDetailDocument();
      });
      this.showDeletePrompt = false;
    },
    async fileUploadEventListener(file) {
      this.isUploading = true;
      this.certSupportingDocumentsUrl = "";
      if (file && file.name) {
        if (file.name.lastIndexOf(".") <= 0) {
          return;
        }
        this.isUploading = true;
        this.isLoading = true;
        const dataFile = await this.setupFileReader(file);
        const result = await uploadFile({
          title: dataFile.name,
          type: dataFile.type,
          size: dataFile.size,
          file: dataFile
        });
        const link = getFileUrl(result.IpfsHash);
        this.certSupportingDocumentsUrl = link;
      }
      this.isUploading = false;
      this.isLoading = false;
    },
    setupFileReader(value) {
      return new Promise((resolve, reject) => {
        const file = value;
        const fr = new FileReader();
        fr.onload = async function () {
          resolve(value);
        };
        fr.onerror = reject;
        fr.readAsArrayBuffer(file);
      });
    },
    closeDialog() {
      this.isShowError = false;
      this.showDeletePrompt = false;
    }
  }
};