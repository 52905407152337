import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":_vm.show,"width":_vm.width == null ? '500' : _vm.width,"persistent":""}},[_c(VCard,[_c(VAppBar,{attrs:{"flat":"","dense":"","color":"white"}},[_c(VToolbarTitle,{staticClass:"title d-flex justify-space-between align-center",staticStyle:{"width":"100%"}},[_vm._t("title"),(_vm.showClose && _vm.$listeners && _vm.$listeners.close)?_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.closeDialog}},[_c(VIcon,[_vm._v("mdi-close")])],1):_vm._e()],2),_c(VSpacer)],1),_c(VCardText,{staticClass:"mt-4 pb-0 text-subtitle-1"},[_vm._t("body")],2),_c(VCardActions,{staticClass:"pb-4 pt-2"},[_vm._t("actions")],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }