//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from "@/components/Button";
export default {
  name: "DialogDeleteConfirmation",
  components: {
    Button
  },
  props: {
    show: Boolean,
    type: String,
    fee: Number
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    },
    handleSubmit() {
      this.$emit("submit");
    }
  }
};