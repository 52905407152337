//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "DialogErrorBalance",
  props: {
    show: Boolean
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    }
  }
};