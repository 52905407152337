//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Dialog",
  props: {
    show: Boolean,
    showClose: {
      type: Boolean,
      default: true
    },
    width: Number
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    }
  }
};